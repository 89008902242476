import { Component, OnInit } from '@angular/core';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { CommonModule } from '@angular/common';
import { ThemeService } from '@services/theme.service';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [CommonModule, NzTypographyModule],
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements OnInit {
  constructor(private themeService: ThemeService) {}

  ngOnInit(): void {
    this.themeService.setNavTitle('Page Not Found');
  }
}
