<div class="w-full flex items-center justify-center px-4 md:px-32 py-40">
  <div class="flex flex-col items-center justify-center gap-6">
    <img src="assets/page-not-found.png" alt="page-not-found" class="h-20" />
    <div class="flex flex-col gap-2 justify-center items-center text-center">
      <h2 nz-typography>This page isn’t available right now.</h2>
      <p nz-typography nzType="secondary">
        This might be because the link is incorrect, or this business has stopped using our service. Please try
        contacting them directly.
      </p>
    </div>
  </div>
</div>
